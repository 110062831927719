:global(#app) {
  .cover {
    // background: url("../../assets/images/cover.jpg") center / cover;
  }

  .descriptionSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 8px;
  }

  .descriptionTitle {
    font-size: 52px;
    margin-bottom: 8px;
  }

  .descriptionWrapper {
    padding: 60px 0 0 80px;
    position: relative;
  }

  .formTitle {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 32px;
  }

  .fullHeight {
    height: 100%;
  }

  .fullHeightPaddingFix {
    height: calc(100% + 1rem);
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .inputWrapper {
    margin-bottom: 16px;
  }

  .loginWrapper {
    margin: 0 auto;
    max-width: 440px;
    padding: 0 20px;
  }

  .logo {
    width: 48px;
  }

  .wrapper {
    background: #fff;
  }
}

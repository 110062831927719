:global(#app) {
  .addCustomerButton {
    background: rgba(0, 0, 0, 0.24);
    border: none;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    fill: rgba(255, 255, 255, 0.72);
    font-weight: normal;
    height: 42px;
    padding: 11px;
    text-align: left;
    transition: background 85ms ease-in, opacity 40ms ease-in,
      border-color 85ms ease-in;
    width: 100%;

    &:active {
      outline: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.32);
    }
  }

  .addCustomerButtonIcon {
    height: 20px;
    padding: 0.64px;
    width: 20px;
  }

  .addCustomerButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .customer {
    margin-right: 20px;
    width: 272px;
  }

  .customers {
    display: inline-flex;
    height: 100%;
    min-width: 100%;
  }

  .panel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .panelItem {
    margin-right: 20px;
  }

  .sidebarLeft {
    padding: 0;
  }

  .wrapperGrid {
    margin: 0 10px;
    margin-top: 0;
    width: 100%;
    background-color: #fff;
  }

  .wrapper {
    margin: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0;
    .buttonToggleSidebar {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 3px;
      background: none;
      box-shadow: none;
      border-radius: 5px 0 0 5px;
      &.buttonActive {
        background-color: #108775;
        opacity: 0.8;
        .dotIcon {
          fill: #fff
        }
      }
      .dotIcon {
        width: auto;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .productInfo {
    margin-right: 35px;
  }

  .quantityHot {
    background-color: #edcb76 ;
  }
}

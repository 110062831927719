:global(#app) {
  .formAddSellitem {
    z-index: 900;
  }
  .field {
    margin-bottom: 4px;
  }
  .fieldGroup {
    margin-bottom: 0;
  }
  .fieldSearchCustomer {
    width: calc(100% - 90px);
    max-width: 100%;
  }
  .formActions {
    width: 90px;
    button {
      padding: 8px 20px;
      line-height: 20px;
    }
  }
}

:global(#app) {
	.addButton {
		min-height: 20px;
		padding: 2px 6px;
		margin-right: 0;
		margin-left: 5px;
	}

	.details {
		div {
		padding-top: 4px;
		padding-bottom: 4px;
		}
	}

	.facebook {
		padding: 0;
	}

	.group {
		padding: 3px 8px;
    	color: #fff;
	}

	.itemDetail {
		// white-space: nowrap;
  		overflow: hidden;
		text-overflow: ellipsis;
	}

	.minus {
		color: #e04556;
	}

	.plus {
		color: #4a8753;
	}

	.wrapper {
		height: calc(100vh - 196px);
		overflow-y: overlay;

    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }
  }
}

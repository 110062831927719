:global(#app) {
  .customerInfo {
    .customerInfoItem {
      display: inline-block;
      margin-right: 30px;
    }
  }
  .gridWrapper {
    margin: 0;
  }
  .hotWrapper {
    width: 100%;
    margin-top: 4px;
    overflow: visible;

    tr:last-child {
      td {
        font-weight: 700;
        color: #000;
        background-color: transparent !important;
        border: none !important;
      }
      td:first-child {
        text-indent: -100px;
      }
    }
  }
  .image {
    padding: 0;
    overflow: hidden;
    text-align: center;

    img {
      display: block;
      height: 42px;
    }
  }
  .shiplineDetailPopup {
    max-height: 90vh;
    overflow-y: auto;
  }
  .shiplineInfo {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    .shipInner {
      display: flex;
      .shipTo {
        margin-right: 30px;
      }
    }
  }
  .wrapper {
    overflow: hidden;
  }
}

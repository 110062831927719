:global(#app) {
  .group {
    margin-top: 140px;
  }

  .flex {
    display: flex;
    height: 100%;
  }

  .message {
    align-content: space-between;
    align-items: center;
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  .messageIcon {
    margin-top: -84px;
  }

  .messageTitle {
    font-size: 32px;
    margin: 24px 0 8px;
  }

  .messageContent {
    font-size: 18px;
    line-height: 1.4;
    margin: 4px 0 0;
    text-align: center;
  }

  .root {
    margin-top: 50px;
  }
  @media (max-width: 850px) {
    .group {
      margin-top: 115px;
    }
  }
}

:global(#app) {
  .item {
    display: inline-block;
    margin-right: 10px;
  }

  .wrapper {
    float: right;
    text-align: right;
    height: 28px;
    line-height: 28px;
    max-width: 50%;
  }
}

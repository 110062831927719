:global(#app) {
  .shipment_wrapper {
    width: 100%;
  }

  .active {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .group {
    color: #fff;
    right: 10px;
    top: 2px;
    z-index: 1;
  }

  .text {
    line-height: 20px;
  }

  .shipmentItem {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    position: relative;
    line-height: 20px;

    .shipment_attribute {
      .shipment_att_label {
        color: #777;
      }

      .shipment_att_value {
        color: #d88159;
        font-weight: 600;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .linkToShipment {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }
}

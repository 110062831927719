@media print {
  .wrapper {
    padding: 15px 30px;
  }
  .wrapper .header {
    display: flex;
  }
  .wrapper .header .date {
    width: 120px;
  }
  .wrapper .header .title {
    width: calc(100% - 120px);
    text-align: center;
    font-weight: 700;
  }
  .wrapper .content {
    margin-top: 30px;
  }

  .actionIcon {
    color: #17394d;
    display: inline;
    margin-right: 8px;
  }

  .actions {
    margin-bottom: 24px;
  }

  .actionsTitle {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.04em;
    margin-top: 16px;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: -4px;
  }

  .addAttachment {
    margin: 0 -4.3px;
    text-decoration: none;
  }

  .attachment {
    display: inline-block;
    margin: 0 4px 4px 0;
    max-width: 100%;
  }

  .attachments {
    display: inline-block;
    margin: 0 8px 8px 0;
    max-width: 100%;
    vertical-align: top;
  }

  .contentModule {
    margin-bottom: 24px;
  }

  .contentPadding {
    padding: 8px 8px 0 16px;
  }

  .arrivalDate {
    background: rgba(9, 30, 66, 0.04);
    border: none;
    border-radius: 3px;
    color: #6b808c;
    cursor: pointer;
    line-height: 20px;
    outline: none;
    padding: 6px 14px;
    text-align: left;
    text-decoration: underline;
    transition: background 0.3s ease;
    vertical-align: top;
  }

  .descriptionButton {
    background: rgba(9, 30, 66, 0.04);
    border: none;
    border-radius: 3px;
    display: block;
    color: #6b808c;
    cursor: pointer;
    min-height: 54px;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
  }

  .descriptionButtonText {
    position: absolute;
    top: 12px;
  }

  .descriptionText {
    background: transparent;
    border: none;
    color: #17394d;
    cursor: pointer;
    line-height: 1.5;
    font-size: 15px;
    margin-bottom: 8px;
    outline: none;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 0;
    text-align: left;
    white-space: pre-line;
    width: 100%;
  }

  .descriptionText img {
    max-width: 100%;
  }
  .grid {
    margin: 0;
  }

  .headerPadding {
    padding: 0;
  }

  .headerTitle {
    color: #17394d;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .headerTitleWrapper {
    margin: 4px 0;
    padding: 6px 0 0;
  }

  .headerWrapper {
    margin: 12px 48px 12px 56px;
    position: relative;
  }

  .modalPadding {
    padding: 0px;
  }

  .moduleHeader {
    color: #17394d;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 0 4px;
    padding: 8px 0;
  }

  .moduleIcon {
    color: #17394d;
    font-size: 17px;
    height: 32px;
    left: -40px;
    line-height: 32px;
    margin-right: 0;
    position: absolute;
    top: 2px;
    width: 32px;
  }

  .moduleWrapper {
    margin: 0 0 0 40px;
    position: relative;
  }

  .sidebarPadding {
    padding: 8px 16px 0 8px;
  }

  .text {
    color: #6b808c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
  }

  .button {
    box-shadow: 0 1px 0 #cbcccc;
  }

  .grid {
    margin: 0;
  }

  .headerPadding {
    padding: 0;
  }

  .headerTitle {
    color: #17394d;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .headerTitleWrapper {
    margin: 4px 0;
    padding: 6px 0 0;
  }

  .headerWrapper {
    margin: 0 0 6px 12px;
    position: relative;
  }

  .modalPadding {
    padding: 0px;
  }

  .moduleHeader {
    color: #17394d;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 0 4px;
    padding: 8px 0;
  }

  .thumbnail {
    border-radius: 3px;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;
    height: 100%;
    left: 0;
    text-align: center;
    text-decoration: none;
    z-index: 1;
  }

  .paymentLayout {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .paymentTitle {
    font-weight: bold;
  }

  .paymentValue {
    color: red;
  }

  .debt {
    color: red;
  }

  .noteHeader {
    text-decoration: none;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .hotWrapper {
    width: 100%;
    margin-top: 10px;
  }

  .sellitemModalPrint {
    display: none;
  }
}

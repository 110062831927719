:global(#app) {
	.addDeposit {
		min-height: 20px;
		padding: 0 4px;
		margin-left: 3px;

	}
  .details {
    max-height: calc(100vh - 148px);
    overflow: auto;
  }
	.facebook {
		padding: 0;
	}

  .listItem {
    padding: 0.5rem 0;
    .listValue {
      text-align: right;
      position: relative;
      min-width: 50px;
      min-height: 20px;
      &.dateValue {
        cursor: pointer;
      }
      .datePicker {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 90;
      }
    }
  }

  .listActions {
    max-height: calc(100vh - 504px);
    .listItem {
      .listValue {
        text-align: left;
        display: flex;
        .actionContent {
          .actionDate {
            color: rgba(0, 0, 0, 0.4);
            font-size: .875em;
          }
        }
        .actionUser {
          margin-right: 5px;
        }
      }
    }
  }

	.itemDetail {
		// white-space: nowrap;
  	overflow: hidden;
		text-overflow: ellipsis;
	}
}

:global(#app) {
  .addCustomerButton {
    background: #fff;
    border: none;
    box-shadow: none;
    margin-top: 1px;
    margin-right: -1px;
    width: 36px;
    height: 36px;
    // position: absolute;
    // right: -1px;
    // top: calc(50% - 21px);

    &:active {
      outline: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }
  .field {
    margin-bottom: 4px;
  
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  .fieldGroup {
    margin-bottom: 0;
    padding-left: 7px;

    >div {
      padding-left: 1px;
      padding-right: 1px;
    }
  }
  .formAddSellitem {
    margin-right: 25px;
    z-index: 900;

    button {
      padding: 8px 8px;
    }
  }
}

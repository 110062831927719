:global(#app) {
  .content {
    display: inline-block;
    width: calc(100% - 44px);
  }

  .deleteButton {
    background: transparent;
    box-shadow: none;
    flex: 0 0 auto;
    font-weight: normal;
    margin: 0 0 0 -10px;
    padding: 11px 10px;
    text-decoration: underline;

    &:hover {
      background: #e9e9e9;
    }
  }

  .email {
    color: #888888;
    font-size: 14px;
    line-height: 1.2;
    padding: 4px 0 4px 2px;
  }

  .name {
    color: #212121;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding: 9px 28px 0 2px;
  }

  .user {
    display: inline-block;
    padding-right: 8px;
    padding-top: 8px;
    vertical-align: top;
  }
}

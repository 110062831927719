:global(#app) {
  .active {
    background-color: rgb(16, 135, 117);
    color: #fff!important;
  }

  .activity {
    font-size: 0.9em;
    font-style: italic;
    margin-left: 20px;
  }

  .buttonOpenMobileMenu {
    height: 35px;
    margin-top: 15px;
    line-height: 25px;
    padding: 5px 10px;
    background-color: transparent !important;
    border: 1px solid #2c3e50;
    font-size: 1.2rem;
    display: none;
    color: #2c3e50;
  }

  .headerLine {
    line-height: 32px;
    padding: 0 4px!important;
  }

  .headerLine2 {
    margin-top: 2px;
  }

  .headerLineLeft {
    display: flex;
    flex-direction: row;
  }

  .item {
    padding: 1em 1em;
    cursor: auto;
    user-select: auto;

    &:before {
      background: none;
    }

    &:active, &:hover {
      background: transparent;
      // color: rgba(255, 255, 255, 0.9);
    }
  }

  .itemHoverable:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.32);
    color: rgba(241, 241, 241, 0.9);
  }

  .logo {
    color: #fff;
    flex: 0 0 auto;
    letter-spacing: 3.5px;
    line-height: 50px;
    padding: 0 16px;
    text-transform: uppercase;
    width: 130px;

    &:before {
      background: none;
    }
  }

  .logoSvg {
    width: 40px;
  }

  .menu {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    flex: 1 1 auto;
    height: 64px;
    margin: 0;
    width: 100%;
    z-index: 1;
  }

  .notification {
    background: #eb5a46;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;
    width: 16px;
  }

  .online {
    padding: 0;
    margin-left: 10px;
  }

  .searchItem {
    padding: 0 !important;
    margin-top: 0px;
    // margin-left: 20px;
  }

  .subItem {
    padding: 7px;
  }

  .subItemHoverable:hover {
    cursor: pointer;
    background: rgba(16, 135, 117, 0.9);
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    padding: 7px !important;
  }

  .user {
    display: inline-block;
    margin: 0 -4px 0 0;
    vertical-align: top;
    line-height: 0;
  }

  .wrapper {
    // background: rgba(0, 0, 0, 0.24);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
    display: flex;
    flex: 0 0 auto;
  }
  @media (max-width: 760px) {
    .editUser {
      display: none;
    }
    .item{
      padding: 1em 0.4em;
    }
    .bgOverlay {
      position: fixed;
      top: 63px;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      height: calc(100vh - 63px);
      width: 100%;
      z-index: 9998;
    }
    .buttonOpenMobileMenu {
      display: inline-block;
    }
    .headerLine1 {
      .headerLineMenu {
        display: inline-block;
        .searchItem {
          margin: 0;
        }
      }
    }
    .headerLine2 {
      position: fixed;
      top: 63px;
      z-index: 9999;
      right: -300px;
      background: #fff;
      height: calc(100vh - 63px);
      width: 300px;
      padding: 0 !important;
      transition: all .3s;
      &.menuActive {
        right: 0;
      }
      .menuLeft {
        display: block;
        .subItem {
          display: block;
          font-size: 1.1rem;
          padding: 10px 15px;
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }
    .headerLineLeft {
      flex-direction: column;
    }
    .rightMenu {
      .itemHoverable {
        padding: 5px 10px;
        font-size: 0.9rem;
      }
    }
    .searchItem {
      margin-top: -5px;
      // margin-left: 20px;
    }
  }
  @media (max-width: 580px) {
    .headerLineLeft {
      display: none;
    }
  }
}

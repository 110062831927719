:global(#app) {
  .addProduct {
    width: 200px;
  }

  .ui.search > .results {
    // top: auto !important;
    // bottom: 30px !important;
    overflow-y: auto;
    max-height: 550px;
  }

  .search {
	  margin: 0 0 2px 0;

	  >div:last-child {
		overflow-y: auto;
		max-height: calc(100vh - 60px);
		// left: -215px;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		  }
	  
		  &::-webkit-scrollbar-track {
			background: transparent;
		  }
	  
		  &::-webkit-scrollbar-thumb {
			border-radius: 3px;
		  }
	  }
	  
	  >div>div>div>div>div {
		  padding: 4px 4px;
	  }
	}

  .searchWrapper {
    width: 100%;
  }

	.result {
		display: flex;
		justify-content: flex-start space-between;
		gap: 10px;
	}

	.title {
		font-weight: 500;
	}

	.description {
		font-size: 0.85em;
		font-weight: 300;
		color:gray;
		display: block;
		flex-grow: 1;
	}

	.price {
		color: green;
	}

  .wrapper {
    display: flex;
  }
}

:global(#app) {
  .addCustomerButton {
    background: rgba(0, 0, 0, 0.24);
    border: none;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    fill: rgba(255, 255, 255, 0.72);
    font-weight: normal;
    height: 42px;
    padding: 11px;
    text-align: left;
    transition: background 85ms ease-in, opacity 40ms ease-in,
      border-color 85ms ease-in;
    width: 100%;

    &:active {
      outline: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.32);
    }
  }

  .addCustomerButtonIcon {
    height: 20px;
    padding: 0.64px;
    width: 20px;
  }

  .addCustomerButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .customer {
    margin-right: 20px;
    width: 272px;
  }

  .customers {
    display: inline-flex;
    height: 100%;
    min-width: 100%;
  }

  .hotWrapper {
    // width: 100%;
    // margin-right: 0px;
    height: calc(100vh - 210px);
  }

  .image {
    padding: 0;
    overflow: hidden;
    text-align: center;

    img {
      display: block;
      height: 42px;
    }
  }

  .panel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .panelItem {
    margin-right: 20px;
  }

  .wrapper {
    margin: 0 10px;
    width: 100%;
    overflow: hidden;
  }
}

:global(#app) {
  .addCustomerButton {
    background: #fff;
    border: none;
    box-shadow: none;
    margin-left: 2px;
    margin-top: 1px;
    width: 40px;
    height: 36px;

    &:active {
      outline: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .actions {
    width: 24px;
    display: inline-block;

    &:hover {
      background-color: rgba(29, 6, 6, 0.15);
      cursor: pointer;
    }

    .dotIcon {
      width: 16px;
      height: 100%;
    }
  }

  .addCustomerButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .code {
    padding: 0 4px;
    display: inline-block;
    background-color: #108775;
    color: white;
    border-radius: 4px;
    font-size: 0.85em;
  }

  .current {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .customer {
    margin-right: 20px;
    width: 272px;
  }

  .customers {
    display: inline-flex;
    height: 100%;
    min-width: 100%;
  }

  .group {
    padding: 3px 5px;
    color: #fff;
    right: 2px;
    top: 2px;
    z-index: 1;
  }

  .hotWrapper {
    width: 100%;
    margin-top: 4px;
    height: calc(100vh - 230px);
  }

  .item {
    position: relative;
  }

  .phone {
    color: #777;
    margin-left: 5px;
  }

  .sidebarLeft {
    padding: 0;
  }

  .wrapperCustomers {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 1px;
    height: calc(100vh - 196px);
    overflow-y: hidden;

    &:hover {
      overflow-y: overlay;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .wrapperSearchCustomers {
    display: flex;
  }
}

:global(#app) {
  .menu {
    margin: -7px -12px -5px;
    width: calc(100% + 24px);
  }

  .menuItem {
    margin: 0;
    padding-left: 14px;
  }

  .meta {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 5px;
    display: inline-block;
    width: 220px; 
    overflow: hidden;
    font-size: 0.85em;
    color: gray;
  }
}

:global(#app) {
  .field {
    margin-bottom: 0;
  }
  .imageWrapper {
    margin-bottom: 0;
  }
  .fieldImageUrl {
    margin: 35px 0 10px;
  }
  .image {
    margin: 0;
    height: auto;
  }
  .imagePreview {
    display: flex;
    height: auto;
    text-align: center;
    .uploadImage {
      width: calc(100% - 60px);
      margin: 30px 10px 0 0;
      height: 30px;
    }
    img {
      width: 70px;
      height: auto;
    }
  }
  .addProductPopup {
    max-height: 95vh;
    overflow-y: auto;
  }
  .addProductGrid {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .addProductImage button {
    font-size: 0.9rem;
    line-height: 1.2;
    padding: 0 1rem;
    min-height: 36px;
    margin-top: 23px;
  }
  .listVariationWrapper {
    margin-top: 0;
    td {
      padding: 1px;
      text-align: center;
    }
  }
  .thumb {
    width: 30px;
    height: 38px;
    height: auto;
    float: left;
    margin-right: 4px;
  }
}

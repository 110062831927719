:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #fff;
    border: 1px solid rgba(9, 30, 66, 0.13);
    border-radius: 3px;
    color: #17394d;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 4px;
    overflow: hidden;
    padding: 8px 12px;
    resize: none;

    &:focus {
      outline: none;
    }
  }
}

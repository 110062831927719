:global(#app) {
  .menu {
    margin: 5px -12px -10px;
    width: calc(100% + 24px);
  }

  .menuItem {
    margin: 0;
    padding-left: 14px;
  }
  .menuIcon, .menuIconFile {
    width: 1.25rem;
    height: 1.25rem;
  }
  .menuIconFile {
    path {
      stroke: #fff;
    }
  }
  .button_edit,
  .button_delete {
    border: none;
    background-color: transparent;
    color: #4c4f52;
    box-shadow: none;
    padding: 0 0.5rem;
    svg {
      color: #fff;
      width: 20px;
    }
  }
  .popupCenter {
    top: 0;
    left: 0;
  }
  .popupEditUser {
    max-height: 90vh;
    overflow: auto;
  }
  .list_passports_wrapper {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 1rem;
  }
}

:global(#app .theme-dark) {
  .button_edit,
  .button_delete {
    color: #9e9e9e;
  }
}

:global(#app) {
  .button {
    background: transparent;
    box-shadow: none;
    line-height: 28px;
    margin: 0;
    min-height: auto;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 28px;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
    }
  }

  .addressButton {
    background: transparent;
    border: none;
    border-radius: 3px;
    color: #6b808c;
    cursor: pointer;
    display: block;
    margin-top: 6px;
    min-height: 54px;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: rgba(9, 30, 66, 0.04);
      color: #092d42;
    }
  }

  .addressButtonText {
    position: absolute;
    top: 12px;
  }

  .contentHoverable:hover {
    position: relative;
    background: rgba(9, 30, 66, 0.04);

    .target {
      opacity: 1;
    }
  }

  .meta {
    color: rgba(0,0,0,.4);
    font-size: .875em;
  }
}

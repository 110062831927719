:global(#app) {
  .actions {
    width: 24px;
    display: inline-block;

    &:hover {
      background-color: rgba(29, 6, 6, 0.15);
      cursor: pointer;
    }

    .dotIcon {
      width: 16px;
      height: 100%;
    }
  }
  .addProductButton {
    background: #fff;
    border: none;
    box-shadow: none;
    margin-left: 2px;
    margin-top: 1px;
    width: 40px;
    height: 36px;

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }

    &::after {
      content: "+";
      position: absolute;
      font-weight: 600;
      font-size: 0.5em;
    }
  }

  .current {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .img {
    margin: 4px 2px 0 0;
    width: 34px;
    height: 44px;
    float: left;
    border: 1px solid #999;
    border-radius: 4px;
  }

  .meta {
    display: inline-block;
    width: calc(100% - 38px);

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.85em;
      line-height: 1.25em;
    }
    span:first-child {
      color: #000;
      font-size: 1em;
      line-height: 22px;
    }
  }


  .sidebarLeftHeader {
    display: flex;
    .searchWrapper {
      width: 100%;
    }
    .input_search {
      width: 100%;
      button{
        padding: 0 1.25em 0 !important;
      }
    }
  }

  .wrapperProducts {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 1px 0 0;
    overflow-y: hidden;
    height: calc(100vh - 188px);
    padding: 0;
   
    &:hover {
      overflow-y: overlay;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

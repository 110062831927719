:global(#app) {
  .menu {
    margin: 0px;
  }

  .tabContent {
    max-width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .wrapper {
    width: 300px;
    background: #fff;
    border: 1px solid #000;
    padding: 0;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

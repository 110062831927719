:global(#app) {
  .message {
    background: #eb5a46;
    border-radius: 4px;
    bottom: 20px;
    box-shadow: #b04632 0 1px 0;
    padding: 12px 18px;
    position: fixed;
    right: 20px;
    width: 390px;
    z-index: 10001;
  }

  .messageContent {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
  }

  .messageHeader {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  .warning {
    background: #eba946;
    border-radius: 4px;
    bottom: 20px;
    box-shadow: #b07b32 0 1px 0;
    padding: 12px 18px;
    position: fixed;
    right: 20px;
    width: 390px;
    z-index: 10001;
  }

  .warningWrapper {
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    position: fixed;
    z-index: 1400;
    height: auto;
    width: auto;
    transition: top 300ms ease 0ms,right 300ms ease 0ms,bottom 300ms ease 0ms,left 300ms ease 0ms,max-width 300ms ease 0ms;
    pointer-events: none;
    max-width: calc(100% - 40px);
  }
}

:global(#app) {
  .field {
    margin-bottom: 8px;
    min-height: auto;
    max-width: 100%;
  }
  .fields {
    margin-bottom: 0;
  }
  .wrapper {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    div, input {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    .buttonToggleForm {
      margin-bottom: 10px;
    }
  }
}

:global(#app) {
  .hotWrapper {
    width: 100%;
    margin-top: 4px;
    height: calc(100vh - 230px);
  }

  .panel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .panelItem {
    margin-right: 20px;
  }

  .quantityHot {
    color: #fff ;
  }

  .sidebarLeft {
    padding: 0;
  }

  .wrapperGrid {
    margin: 0 10px;
    // margin-top: -64px;
    width: 100%;
  }

  .wrapper {
    margin: 0 0px;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
}

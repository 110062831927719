:global(#app) {
  .menu {
    margin: -7px -12px -5px;
    width: calc(100% + 24px);
  }

  .menuItem {
    margin: 1px;
    padding: 8px;
    padding-left: 14px;
    color: white;

    &:hover {
      opacity: 0.85;
    }
  }
}

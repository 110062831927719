:global(#app) {
  .action {
    margin-right: 20px;
  }

  .actions {
    align-items: center;
    display: flex;
    margin: 5px 10px 0 10px;
  }

  .buttonFilter{
    margin: 5px 10px 0 10px;
    height: 36px;
    width: 36px;
    display: none;
  }
  .buttonMinimize{
    display: none;
  }

  @media (max-width: 850px) {
    .actions {
      display: none;
      &.menuActive {
        display: flex;
      }
    }
    .buttonFilter{
      display: inline-block;
    }
    .buttonAction{
      display: none;
      &.menuActive {
        display: inline-block;
      }
    }
    .buttonMinimize{
      display: inline-block;
      margin-bottom: auto;
      margin-left: auto;
      background: none;
      box-shadow: none;
    }
  }
}

:global(#app) {
  .wrapper {
    margin: 4px 0px 0px 5px;
   
    input {
      height: 28px;
      // background: none;
      // color: rgba(255, 255, 255, 0.9)!important;
      // border-color: rgba(255, 255, 255, 0.1)!important;
    }

    i.search.icon {
      color: rgba(255, 255, 255, 0.9)!important;
    }
  }

  .ui.search > .results {
    // top: auto !important;
    // bottom: 30px !important;
    overflow-y: auto;
    max-height: 550px;
  }

  .search {
	  margin: 0 0 2px 0;

	  >div:last-child {
		overflow-y: auto;
		max-height: calc(100vh - 60px);
		// left: -215px;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		  }
	  
		  &::-webkit-scrollbar-track {
			background: transparent;
		  }
	  
		  &::-webkit-scrollbar-thumb {
			border-radius: 3px;
		  }
	  }
	  
	  >div>div>div>div>div {
		  padding: 4px 8px;
	  }

		.title {
			// text-transform: uppercase;
		}

	  .description {
		  font-size: 0.85em;
		  font-weight: 300;
		  color:gray;
		  display: block;
	  }

		.birthday {
			font-size: 0.85em;
		  font-weight: 300;
		  color: #108775;
			margin-left: 10px;
		}
	}
}

:global(#app) {
  .wrapper {
    
    // div {
    //   position: relative;
    // }
    div > div:nth-child(4) {
      // margin-top: 1px;
      // margin-bottom: 0;
      // padding: 0;

      div {
        // overflow-x: hidden;
        padding: 0;
      }

      div > div {
        width: calc(100% - 4px);
        margin-left: -2px;
        margin-right: -4px;
        padding: 6px 4px;
      }
    }

    // div.status > div:nth-child(4) {
  
    //   div {
    //     div:nth-child(1) {
    //       color: #f0982d;
    //     }
    //     div:nth-child(2) {
    //       color: #109dc0;
    //     }
    //     div:nth-child(3) {
    //       color: #c7a57b;
    //     }
    //     div:nth-child(4) {
    //       color: #8aa177;
    //     }
    //     div:nth-child(5) {
    //       color: #f97394;
    //     }
    //     div:nth-child(6) {
    //       color: #ad5f7d;
    //     }
    //     div:nth-child(7) {
    //       color: #355263;
    //     }
    //     div:nth-child(8) {
    //       color: #87564a;
    //     }
    //     div:nth-child(9) {
    //       color: #afb0a4;
    //     }
    //     div:nth-child(10) {
    //       color: #f7d036;
    //     }
    //     div:nth-child(11) {
    //       color: #4a8753;
    //     }
    //   }
    // }
  }

  
}

:global(#app) {
  .actionButtons {
    max-height: 28px;
    button {
      max-height: 28px;
      padding: 0px 8px;
    }
    i {
      font-size: 0.85rem;
      width: 2rem;
    }
    .buttonTableActionRedo {
      padding: 5px 35px 5px 10px !important;
    }
    .buttonTableActionUndo {
      padding: 5px 10px 5px 35px !important;
    }
  }

  .image {
    padding: 0;
    overflow: hidden;
    text-align: center;

    img {
      display: block;
      height: 42px;
    }
  }

  .oddeven {
    table tr:nth-of-type(even) > td {
      background-color: hsl(199, 100%, 95%);
    }
  }

  .twoLines {
    a {
      overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 2; /* number of lines to show */
       -webkit-box-orient: vertical;
    }
  }

  .wrapper {
    width: 100%;
    margin-top: 4px;
    height: calc(100vh - 175px);
  }
}

:global(#app) {
  .add {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 6px, rgba(0, 0, 0, 0.1) 0 6px 12px;
    color: #333;
    cursor: pointer;
    fill: #333;
    text-align: center;
    width: 100%;

    &:hover {
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.1);
      transform: translate(0, -2px);
    }
  }

  .addGridIcon {
    display: block;
    margin: 0 auto 12px;
    width: 36px;
  }

  .addTitle {
    display: table-cell;
    font-size: 17px;
    line-height: 1;
    vertical-align: middle;
  }

  .addTitleWrapper {
    display: table;
    height: 100%;
    padding: 35px 21px;
    width: 100%;
  }

  .sellitem {
    border-radius: 4px;
    height: 150px;
    transition: all 200ms ease;
  }

  .sellitemOverlay {
    background: #000000;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
  }

  .sellitemsWrapper {
    flex: 1 1 auto;
    max-height: 100%;
  }

  .gridFix {
    flex: 1 0 auto;
    margin: 0;
  }

  .notification {
    background: #eb5a46;
    color: #fff;
    float: right;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 6px;
    border: none;
    border-radius: 3px;
    outline: none;
    text-align: left;
    transition: background 0.3s ease;
  }

  .open {
    background: #555;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.1);
    color: #fff;
    position: relative;
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
      box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.1);
      transform: translate(0, -2px);
    }
  }

  .openTitle {
    bottom: 0;
    font-size: 24px;
    hyphens: auto;
    left: 0;
    line-height: 1.1;
    padding: 24px 20px;
    position: absolute;
    word-break: break-word;
  }

  .business {
    background: #22252a;
    flex: 1 1 auto;
    height: 100%;
    width: calc(100% - 200px);
  }

  .wrapper {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
  }
}

@media print {
  .wrapperShipmentPrint {
    padding: 15px 30px;
  }
  .wrapperShipmentPrint .header {
    display: flex;
  }
  .wrapperShipmentPrint .header .date {
    width: 120px;
  }
  .wrapperShipmentPrint .header .title {
    width: calc(100% - 120px);
    text-align: center;
    font-weight: 700;
  }
  .wrapperShipmentPrint .content {
    margin-top: 30px;
  }
}
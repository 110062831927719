:global(#app) {
  // .hotWrapper {
  //   width: 678px;
  //   height: auto;
  // }
  .wrapper {
    border: none;
    box-shadow: none;
    padding: 0;
  }
}

:global(#app) {
  .menu {
    margin: 0px;
  }

  .fieldAmount {
    color: #000;
    opacity: 1;
    :disabled {
      opacity: 1;
    }
  }

  .field {
    // padding-bottom: 0;
    // padding-right: -10px;
  }

  .wrapper {
    // display: none;
    // position: absolute;
    // right: -90px;
    // top: 0;
    // width: 160px;
    background: #fff;
    border: 1px solid #000;
    padding: 10px 10px 0px 10px;
    // overflow: hidden;
    // z-index: 999;
  }
  
}

:global(#app) {
  .button {
    box-shadow: 0 1px 0 #cbcccc;
  }
  
  .grid {
    background: #f5f6f7;
    margin: 0;
  }

  .headerPadding {
    padding: 0;
  }

  .headerTitle {
    color: #17394d;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .headerTitleWrapper {
    margin: 4px 0;
    padding: 6px 0 0;
  }

  .headerWrapper {
    margin: 12px 48px 12px 56px;
    position: relative;
  }

  .modalPadding {
    padding: 0px;
  }

  .moduleHeader {
    color: #17394d;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 0 4px;
    padding: 8px 0;
  }
}

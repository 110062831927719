:global(#app) {
  .action {
    margin-right: 20px;
  }

  .actions {
    align-items: center;
    display: flex;
    margin: 5px 10px 0 10px;
  }
}

:global(#app) {
  .actionButtons {
    max-height: 28px;
    button {
      max-height: 28px;
      padding: 0px 8px;
    }
    i {
      font-size: 0.85rem;
      width: 2rem;
    }
    .buttonTableActionRedo {
      padding: 5px 35px 5px 10px !important;
    }
    .buttonTableActionUndo {
      padding: 5px 10px 5px 35px !important;
    }
  }
  .addShipmentButton {
    background: #fff;
    border: none;
    box-shadow: none;
    margin-left: 2px;
    margin-top: 1px;
    width: 40px;
    height: 36px;

    &:active {
      outline: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .actions {
    width: 24px;
    display: inline-block;

    &:hover {
      background-color: rgba(29, 6, 6, 0.15);
      cursor: pointer;
    }

    .dotIcon {
      width: 16px;
      height: 100%;
    }
  }

  .addShipmentButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .buttonDetail {
    background-color: #2563EB;
    color: #fff;
    border-color: #2563EB;
    border-radius: 5px;
    font-size: 0.85em;
    cursor: pointer;
  }

  .buttonAddShipment {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .fixcolumn{
    padding: 0.0rem 0.5rem 0.5rem 0.5rem !important;
  }

  .hotWrapper {
    width: 100%;
    margin-top: 4px;
    height: calc(100vh - 220px);
  }

  .notRightSidebar{
    display: none;
    margin-bottom: 0em;
  }

  .panel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .panelItem {
    margin-right: 20px;
  }

  .rightSidebar{
    display: block;
    margin-bottom: 0em;
  }

  .shipment {
    margin-right: 20px;
    width: 272px;
  }

  .shipments {
    display: inline-flex;
    height: 100%;
    min-width: 100%;
  }

  .sidebarLeft {
    padding: 0;
    margin-bottom: 0em;
  }

  .shipmentPrint {
    display: none;
  }

  .topTableShipment {
    display: flex;
    .buttonPrintShipment {
      margin: 0 0 0 14px;
      line-height: 20px;
      min-height: 32px;
      padding: 5px 12px;
      box-shadow: none;
    }
    .searchCustomersWrapper {
      width: calc(100% - 115px);
    }
  }

  .wrapperShipments {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 1px;
    max-height: calc(100vh - 196px);
    overflow-y: hidden;

    &:hover {
      overflow-y: overlay;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .wrapperGrid {
    margin: 0 10px;
    // margin-top: -64px;
    width: 100%;
  }

  .wrapper {
    padding: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .wrapperSearchShipments {
    display: flex;
  }

  @media (max-width: 1199px) {
    .notRightSidebar{
      display: block;
    }
    .rightSidebar{
      display: none !important;
    }
  }

  @media (max-width: 767px) {
    .fixcolumn{
    padding: 1rem 0rem !important;
  }
  }
}

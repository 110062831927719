:global(#app) {
  .cardInfoWrapper {
    display: flex;
    border: 1px solid #D4D4D8;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    .cardIcon {
      width: 60px;
      height: 100%;
      min-height: 60px;
      font-size: 20px;
      text-align: center;
      border-radius: 5px 0 0 5px;
      border-right: 1px solid #D4D4D8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cardData {
      padding: 8px 20px;
      width: calc(100% - 60px);
    }
    .cardTitle {
      margin-bottom: 5px;
    }
    .cardValue {
      display: flex;
      .cardValueNumber {
        width: 100%;
        font-weight: 700;
        font-size: 1.3rem;
      }
      .cardValuePerson {
        color: #22C55E;
        font-weight: 500;
      }
    }
  }
  .dashboardWrapper {
    margin: 0 10px;
    margin-top: 5px;
    background-color: #fff;
    .chartLabel {
      font-size: 0.9rem;
      margin-left: 30px;
      color: #555;
      .chartLabelItem::before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        border: 1px solid #14B8A6;
        background-color: #14B8A6;
        width: 25px;
        height: 10px;
      }
    }
    .dashboardContent {
      height: calc(100vh - 80px);
      overflow: auto;
      .dashboardContentList {
        margin-top: 0;
      }
      .lastRow {
        padding-bottom: 0;
      }
    }
    .sidebarLeftContent {
      height: calc(100vh - 148px);
      overflow: auto;
      display: block;
      margin-top: 0rem;
      .listHeader {
        display: flex;
      }
      .filterTopTitle {
        width: 100%;
        line-height: 36px;
        margin: 0;
      }
      .filterTop {
        width: 160px;
        min-width: 160px;
      }
    }
    .sidebarLeftGrid {
      display: none;
    }
    .sidebarLeftContentMobile {
      height: calc(100vh - 148px);
      overflow: auto;
      display: none;
      margin-top: 0rem;
      .listHeader {
        display: flex;
      }
      .filterTopTitle {
        width: 100%;
        line-height: 36px;
        margin: 0;
      }
      .filterTop {
        width: 160px;
        min-width: 160px;
      }
    }
    .titleInterval {
      margin-bottom: 5px;
    }
    .titleGraph {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  .filterDate {
    .dateRange {
      input {
        width: 100%;
        border: none;
        font-weight: 700;
        font-size: 1.25rem;
        box-shadow: none;
        outline: none;
      }
    }
    .filterLastPeriod {
      display: flex;
      .labelFilterLastPeriod {
        display: inline-block;
        line-height: 24px;
      }
      .filterDatePeriod {
        border: 0;
        width: calc(100% - 80px);
        padding: 5px 10px;
        min-height: 20px;
        > i {
          padding: 5px 10px;
          min-height: 20px;
        }
      }
    }
  }
  .graphWrapper {
    position: relative;
    .filterChartWrapper {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 15px;
      .buttonItem {
        padding: 0 10px;
        font-size: 0.85rem;
        line-height: 1.5rem;
      }
    }
  }
  .gridChart {
    margin-top: 0;
  }
  .listWrapper {
    height: 100%;
    .wrapperCustomers {
      margin: 0;
    }
    .listSellitemsAmountDone {
      margin-left: 20px;
      margin-top: 0;
    }
    .listItem {
      padding: 8px 0;
      .listValue {
        text-align: right;
        color: #1D4ED8;
        opacity: 0.7;
        .customerName {
          font-weight: 700;
          opacity: 1;
        }
      }
    }
    .listItemSpace {
      height: 50px;
    }
    .wrapperStatuses {
      .listItem {
        padding: 3px 0;
        .listValue {
          line-height: 28px;
          text-align: right;
          color: #1D4ED8;
          opacity: 0.5;
        }
      }
    }
  }
  .topItemWrapper {
    display: flex;
    margin-bottom: 10px;
    .itemLeft{
      width: 35px;
      i {
        margin: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 18px;
        line-height: 35px;
        text-align: center;
        background-color: #A1A1AA;
      }
    }
    .itemCenter {
      width: calc(100% - 85px);
      padding: 0 10px;
      .itemName {
        font-weight: 700;
      }
      .itemInfo {
        font-size: 0.9rem;
        opacity: 0.7;
        .textOrange {
          color: #FB923C;
        }
        .textBlue {
          color: #3B82F6;
        }
      }
    }
    .itemRight {
      display: inline-block;
      font-size: 0.9rem;
      width: 50px;
      height: 20px;
      line-height: 20px;
      background: #FB923C;
      border-radius: 5px;
      text-align: center;
      color: #fff;
    }
  }
  .sidebarLeftFilter {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    box-shadow: none;
  }
  canvas{ 
    max-height: 300px;
    min-height: 250px;
  }
  .wrapperRevenue {
    .revenueItemWrapper {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .itemLeft{
        width: 35px;
        span {
          display: inline-block;
          margin: 0;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: -1px;
          line-height: 35px;
          text-align: center;
          background-color: #A1A1AA;
        }
      }
      .itemCenter {
        width: calc(100% - 35px);
        padding: 0 10px;
        display: flex;
        align-items: center;
        .itemName {
          font-weight: 700;
          margin: 0 15px 0 0;
        }
        .itemInfo {
          font-size: 0.9rem;
          opacity: 0.7;
          .textOrange {
            color: #FB923C;
          }
          .textBlue {
            color: #3B82F6;
          }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .dashboardWrapper {
      .dashboardContent {
        height: auto;
        overflow: hidden;
      }
    }
    .sidebarLeftContent {
      height: auto !important;
      display: none !important;
      max-height: calc(100vh - 148px);
    }
    .sidebarLeftContentMobile {
      height: auto !important;
      display: block !important;
      max-height: calc(100vh - 148px);
    }
    .sidebarLeftGrid {
      display: block !important;
    }
  }
  @media all and (max-width: 580px) {
    .titleGraph {
      text-align: left !important;
    }
    .filterChartWrapper{
      top: 5px !important;
      right: 5px !important;
    }
    .buttonItem{
      padding: 6px !important;
    }
  }
}

:global(#app) {
  .cardInfoWrapper {
    display: flex;
    border: 1px solid #D4D4D8;
    border-radius: 5px;
    .cardIcon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      text-align: center;
      border-radius: 5px 0 0 5px;
      border-right: 1px solid #D4D4D8;
    }
    .cardData {
      padding: 8px 20px;
      width: calc(100% - 60px);
    }
    .cardTitle {
      margin-bottom: 5px;
    }
    .cardValue {
      display: flex;
      .cardValueNumber {
        width: 100%;
        font-weight: 700;
        font-size: 1.3rem;
      }
      .cardValuePerson {
        color: #22C55E;
        font-weight: 500;
      }
    }
  }
  .dashboardWrapper {
    margin: 0 10px;
    margin-top: 5px;
    background-color: #fff;
    .titleGraph {
      text-align: center;
    }
    .sidebarLeftContent {
      height: calc(100vh - 148px);
      overflow: auto;
      margin-top: 0.5rem;
      .listHeader {
        display: flex;
      }
      .filterTopTitle {
        width: calc(100% - 160px);
        line-height: 36px;
        margin: 0;
      }
      .filterTop {
        width: 160px;
        min-width: 160px;
      }
    }
    .dashboardContent {
      height: calc(100vh - 80px);
      overflow: auto;
      .dashboardContentList {
        margin-top: 0;
      }
      .lastRow {
        padding-bottom: 0;
      }
    }
  }
  .filterDate {
    .dateRange {
      input {
        width: 100%;
        border: none;
        font-weight: 700;
        font-size: 1.25rem;
        box-shadow: none;
        outline: none;
      }
    }
    .filterLastPeriod {
      display: flex;
      .labelFilterLastPeriod {
        display: inline-block;
        line-height: 24px;
      }
      .filterDatePeriod {
        border: 0;
        width: calc(100% - 80px);
        padding: 5px 10px;
        min-height: 20px;
        > i {
          padding: 5px 10px;
          min-height: 20px;
        }
      }
    }
  }
  .listWrapper {
    height: 100%;
    .listItem {
      padding: 8px 0;
      .listValue {
        min-width: 100px;
        text-align: right;
      }
    }
    .listItemSpace {
      height: 50px;
    }
  }
  .topItemWrapper {
    display: flex;
    margin-bottom: 10px;
    .itemLeft{
      width: 35px;
      i {
        margin: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 18px;
        line-height: 35px;
        text-align: center;
        background-color: #A1A1AA;
      }
    }
    .itemCenter {
      width: calc(100% - 85px);
      padding: 0 10px;
      .itemName {
        font-weight: 700;
      }
      .itemInfo {
        font-size: 0.9rem;
        opacity: 0.7;
        .textOrange {
          color: #FB923C;
        }
        .textBlue {
          color: #3B82F6;
        }
      }
    }
    .itemRight {
      display: inline-block;
      font-size: 0.9rem;
      width: 50px;
      height: 20px;
      line-height: 20px;
      background: #FB923C;
      border-radius: 5px;
      text-align: center;
      color: #fff;
    }
  }
  .sidebarLeftFilter {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    box-shadow: none;
  }
}

:global(#app) {
  .image {
    width: 32px;
    height: 32px;
    margin: -6px;
  }
  .list {
    margin-top: 5px;
  }
  .price {
    margin-left: 10px;
    color: green;
  }
  .wrapper {
    background-color: white;
    padding: 1px;
    border: 1px solid gray;
    padding-bottom: 5px;
  }
}

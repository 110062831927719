:global(#app) {
	.addDeposit {
		min-height: 20px;
		padding: 0 4px;
		margin-left: 3px;
	}
  	.details {
    	max-height: calc(100vh - 148px);
    	overflow: auto;
    	.addStock{
      		min-height: 20px;
      		padding: 0 4px;
      		margin-left: 3px;
    	}
    	div {
			padding-top: 4px;
			padding-bottom: 4px;
		}
		.list_info {
			border: none;
		}
		.listVariation {
			max-width: calc(100% - 60px);
			text-align: right;
			text-overflow: ellipsis;
			overflow-x: hidden;
		}
		.headerName {
			margin: 0;
			padding: 0;
		}
		.productName {
			margin: 0;
		}
		.productInfo {
			font-size: 0.9rem;
			.productBrand {
			  display: inline-block;
			  padding: 0 5px;
			  border-radius: 5px;
			  background-color: #f1f1f1;
			  color: #333;
			  min-height: 20px;
			  line-height: 20px;
			}
			.productCountry {
			  display: inline-block;
			  padding: 0 8px;
			  border-radius: 5px;
			  background-color: #999;
			  color: #fff;
			  margin-left: 3px;
			  min-height: 20px;
			  line-height: 20px;
			}
		}
		.productImage {
			max-width: 90%;
			border: 1px solid #999;
			border-radius: 5px;
			padding: 5px;
			text-align: center;
			margin: 5px auto 0;
			img {
				max-width: 100%;
			}
		}
		.stocksLabel {
			cursor: pointer;
		}
 	}

	.facebook {
		padding: 0;
	}

	.itemDetail {
		// white-space: nowrap;
  	overflow: hidden;
		text-overflow: ellipsis;
	}

	.btnEdit {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 5px;
		height: auto;
		line-height: 20px;
		min-height: 20px;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		border-radius: 5px 0 0 5px;
		&.buttonActive {
		  background-color: #108775;
		  opacity: 0.8;
		  .dotIcon {
			fill: #fff
		  }
		}
	}	
}
